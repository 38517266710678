.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

.dropzone__lew{
	border: 1px solid rgb(206, 205, 204);
	
	border-radius: 1.25rem;
	line-height: 5rem;
	text-align:center;
	cursor: pointer;
	background-color: #f5f5f5;
}

.dropzone__wrapper{
	padding-bottom: 1rem;
}

.dropzone__lew{
	min-height: 5rem;
}

.dropzone__lew p{
	line-height: 5rem;
}