progress {
    display: block;
    width: 100%;
    height: 55px;
    padding: 15px 0 0 0;
    margin: 0;
    background: none;
    border: 0;
    border-radius: 50px;
    text-align: left;
    position: relative;
    font-family: "Helvetica Neue", arial, sans-serif;
    font-size: 15px;
    
}

    progress::-webkit-progress-bar {
        height: 50px;
        padding-left: 0px;
        margin: 0 auto;
        background-color: white;
        border-radius: 100px;
        border: 1px solid;
        border-color: lightgray
    }

    progress::-webkit-progress-value {
        display: inline-block;
        float: left;
        height: 48px;
        margin: 0px -10px 0 0;
        padding-left: 50px;
        background: #123abc;
        border-radius: 100px;
    }

    progress.skill-1::-webkit-progress-value {
        background: #ff9e2c;
    }

    progress.skill-2::-webkit-progress-value {
        background: #4ecdc4;
    }

    progress.skill-3::-webkit-progress-value {
        background: #ff6b6b;
    }

    progress:after {
        margin: -25px 0 0 -32px;
        padding: 0;
        display: inline-block;
        float: left;
        content: attr(value) '%';
        color: white;
    }
