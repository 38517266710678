
.field-group.select-replace .custom-options-wrapper {
  max-height: 320px;
}
@media (max-width: 799px)  {
  .custom-options {
      display: none;
  }
  
  html:not([class=""]) [data-tpl="form-v2"] .field-group.select-replace select.form-control__field {
    z-index: 2;
  }
}